<template>
  <watch-container />
</template>

<script>
  import WatchContainer from './watch.container';

  export default {
    name: 'WatchView',
    components: {
      WatchContainer,
    },
  };
</script>

<style></style>
<template>
  <div class="watch-container">
    <shop-player
      ref="shopPlayer"
      :items="shopPlayerItems"
      :is-loading="shopPlayerItemsLoading"
      :class="{'product-popup--active': productPopupDetails}"
      :scroll-navigate="true"
      :drag-navigate="true"
      :shop-controls="shopPlayerControls"
      :active-item="shopPlayerActiveItem"
      :items-products="itemsProducts"
      :items-stats="itemsStats"
      :items-overlay-state="itemsOverlaysState"
      :items-channel-follow-state="itemsChannelsFollowState"
      :chat-config="chatConfig"
      :full-page-mode="shopPlayerFullPageMode"
      :hot-keys="true"
      :use-high-initial-bitrate="true"
      :loop="activeTab && activeTab.contentType === 'vod'"
      :logo-link="logoLink || require('src/assets/logo/logo-vimmi-white.svg')"
      :background="'purple'"
      :key-by="'slug'"
      :tabs="activeTabs"
      :active-tab="activeTab"
      :loop-tabs="true"
      :rewind="true"
      :viewers-limit-reached="viewersLimitReached"
      :load-tab-items="loadTabItems"
      :get-playback-url="getPlaybackUrl"
      :can-play="canPlay"
      @select:tab="selectTab"
      @playback-state="shopPlayerPlaybackStateUpdated($event)"
      @area-click="shopPlayerAreaClick"
      @play-btn-click="shopPlayClick"
      @fs-state="fullScreenChanged"
      @active-item="setShopPlayerActiveItem($event)"
      @toggle-follow="toggleFollow($event)"
      @block-scroll="blockPageScroll($event)"
      @unblock-scroll="unBlockPageScroll($event)"
      @chat-user-updated="chatUserUpdated($event)"
      @need-login="showLoginPopup($event)"
      @product-click="onProductClick($event)"
      @close-product="closeProductsDetailsPopup($event)"
      @send-reaction="sendReaction($event)"
      @share="openSharePopup($event)"
      @player:stats="shopPlayerStats($event, 'watch')"
    >
      <template #products-details-popup>
        <product-details-popup-component
          v-if="productPopupDetails"
          :product="productPopupDetails"
          :media-source="activeTab && activeTab.contentType"
          @close="closeProductsDetailsPopup"
        />
      </template>
    </shop-player>
  </div>
</template>

<script>
  import device from '@/service/device-service';
  import ProductDetailsPopupComponent from '@/shared/components/shop-player/product-details-popup.component.vue';
  import ShopPlayerMixin from '@/shared/mixins/shop-player.mixin';
  import { AuthVariableMixin, ScreenReadyMixin } from '@/plugins/mixin';
  import RecommendationsApi from '@/shared/api/recommendations';
  import { mapActions, mapState } from 'vuex';
  import rtm from '@/service/realTimeMessages';
  import { debounce } from 'lodash';
  import { amsClient } from '@/service/ams';
  import ShoprzStore from '@/store/shoprz';

  import Config from '@/service/config';
  import ShopPlayerService from '@/shared/services/shop-player.service';

  export default {
    name: 'WatchContainer',
    components: {
      ProductDetailsPopupComponent,
    },
    mixins: [AuthVariableMixin, ScreenReadyMixin, ShopPlayerMixin],
    data() {
      return {
        shopPlayerItemsLoading: true,
        shopPlayerFullPageMode: true,
        shopPlayerActiveItem: null,
        shopPlayerPlaybackState: {},
        logoLink: '',
        activeTab: null,
        tabs: [
          {
            id: 'live',
            title: 'Live',
            link: '/recommendation/live',
            contentType: 'live',
          },
          {
            id: 'vod',
            title: 'Videos',
            link: '/recommendation/vod',
            contentType: 'vod',
          },
        ],
        tabsItems: {},
      };
    },
    computed: {
      ...mapState('popupPlayer', {
        vodPopupItem: 'item',
        vodPopupPlaybackState: 'playbackState',
      }),

      shopPlayerItems() {
        let items = [];

        if (!this.activeTab) {
          return items;
        }
        items = this.tabsItems[this.activeTab.id];

        items.forEach((item) => {

          item.uniq_id = this.generateUniqIdForPlayer(item);
          item.tab_id = item.tab_id || this.activeTab.id;

          this.$set(
            this.itemsChannelsFollowState,
            item.id,
            ShoprzStore.isChannelFollowed(item?.creator?.id || item?.storefront?.id),
          );
        });

        return items;
      },
      isMobile() {
        return device.isAnyMobile() || device.isMobileSize();
      },
      shopPlayerControls() {
        return {
          volume: true,
          fullscreen: true,
          pip: false,
          upDownToggler: true,
          productsDrawer: !this.shopPlayerActiveItem?.vod_short,
        };
      },
      activeTabs() {
        return (this.tabs || []).filter((tab) => {
          return this.tabsItems[tab.id] && this.tabsItems[tab.id].length;
        });
      },
    },
    watch: {
      vodPopupPlaybackState: function(val, prevVal) {
        if (this.$refs.shopPlayer && this.shopPlayerActiveItem) {
          if (!val.paused && !this.shopPlayerPlaybackState.paused) {
            // this.shopPlayerPlayStateBeforePopup = !this.shopPlayerPlaybackState.paused;
            this.$refs.shopPlayer.pause(null, false);
          }
        }
      },
      floatPlaybackState: function(val, prevVal) {
        if (this.$refs.shopPlayer && this.shopPlayerActiveItem) {
          if (!val.paused && !this.shopPlayerPlaybackState.paused) {
            // this.shopPlayerPlayStateBeforePopup = !this.shopPlayerPlaybackState.paused;
            this.$refs.shopPlayer.pause(null, false);
          }
        }
      },
    },
    created() {
      this.$bus.$emit('toggleFooter');
      Config.loaded(() => {
        this.logoLink = amsClient.get('conf.store.logo');
      });
    },
    mounted() {
      document.body.classList.add('watch-shop-player');
      this.$nextTick(() => {
        this.$bus.$emit('handleSpecificClass', this.shopPlayerSpecialClasses);
        this.$bus.$emit('handleHeaderSpecificClass', 'shop-player-header');
        this.init();
        this.$stats.send('page_opened', {
          page_type: 'watch',
        });
        this.$bus.$emit('updateTitle', 'Watch');
      });

    },
    beforeDestroy() {

      this.$bus.$emit('toggleFooter');
      document.body.classList.remove('watch-shop-player');
      this.$bus.$emit('handleSpecificClass', '');
      this.$bus.$emit('handleHeaderSpecificClass', '');
    },

    methods: {
      ...mapActions({
        setFloatItem: 'floatPlayer/setFloatItem',
        setFloatOptions: 'floatPlayer/setFloatOptions',
        setFloatPlaybackState: 'floatPlayer/setPlaybackState',
      }),
      async onChangedUserState() {
        console.log('onChangedUserState watch');
        if (this.floatItem && !this.canPlay(this.floatItem)) {
          this.hideFloatPlayer();
        }
        if (this.shopPlayerActiveItem && !this.canPlay(this.shopPlayerActiveItem)) {
          this.shopPlayerActiveItem = { ...this.shopPlayerActiveItem };
        }
      },
      async init() {
        const slug = this.$route.params.slug;
        const source = this.$route.query.source;
        const product = this.$route.query.product;
        const platform = this.$route.query.platform;

        let targetItem;

        this.setFloatItem(null);
        this.setFloatOptions(null);

        this.shopPlayerItemsLoading = true;


        await Promise.all(this.tabs.map((tab) => {
          return this.loadShopPlayerItems(tab.contentType, tab).then((items) => {
            this.$set(this.tabsItems, tab.id, items);
            return items;
          });
        }));

        const liveTab = this.tabs.find((tab) => tab.contentType === 'live');
        const vodTab = this.tabs.find((tab) => tab.contentType === 'vod');

        if (slug) {
          if (source === 'live' || !source) {
            const targetItemIndex = this.tabsItems[liveTab.id].findIndex((item) => {
              return slug === item.slug;
            });

            if (targetItemIndex >= 0) {
              targetItem = await this.loadFullItem(this.tabsItems[liveTab.id][targetItemIndex]);
              this.tabsItems[liveTab.id].splice(targetItemIndex, 1);
              this.$set(this.tabsItems, liveTab.id, [targetItem, ...this.tabsItems[liveTab.id]]);
            }
          }
          if (!targetItem && (source === 'vod' || !source)) {
            try {
              targetItem = await this.loadFullItem({ slug });
              this.$set(this.tabsItems, vodTab.id, [targetItem, ...this.tabsItems[vodTab.id]]);
            } catch (e) {
              console.error(e);
            }
          }
          if (!targetItem && product) {
            this.$router.push({ name: 'product-id', params: { id: product } });
            return;
          }
          if (slug && product && targetItem.slug === slug) {
            this.rewindToProduct[targetItem.id] = product;
          }
          // if (targetItem) {
          //   this.shopPlayerActiveItem = targetItem;
          // }
        }

        if (!targetItem) {
          targetItem = this.tabsItems[liveTab.id][0] || this.tabsItems[vodTab.id][0];
        }

        this.activeTab = targetItem?.is_live ? liveTab : vodTab;
        if (targetItem) {
          await this.setShopPlayerActiveItem(targetItem);
        }
        this.$nextTick(() => {
          this.shopPlayerItemsLoading = false;
        });

        this.listenRtm();
      },
      loadTabItems(tab) {
        return this.tabsItems[tab.id];
      },
      listenRtm() {
        this.rtmSubscription = rtm.on(['started', 'on_air_now', 'finished'], debounce(async (data) => {

          const liveTab = this.tabs.find((tab) => tab.contentType === 'live');
          const vodTab = this.tabs.find((tab) => tab.contentType === 'vod');
          if (data.op === 'finished') {
            setTimeout(() => {
              if (this.activeTab?.contentType === 'live') {
                const index = this.tabsItems[liveTab.id].findIndex((liveItem) => liveItem.id === data.room);
                const finishedItem = this.tabsItems[liveTab.id][index];
                this.$set(this.tabsItems, liveTab.id, this.tabsItems[liveTab.id].filter((liveItem) => liveItem.id !== data.room));
                if (!this.tabsItems[liveTab.id].length) {
                  this.selectTab({ tab: vodTab });
                } else if (finishedItem?.id === this.shopPlayerActiveItem?.id) {
                  if (this.floatItem?.id === this.shopPlayerActiveItem.id) {
                    /** todo: play next/prev live in float player or just hide it */
                    this.hideFloatPlayer();
                  }

                  if (finishedItem) { // todo: temporary for google analytics, need to refactor
                    let pPercents = this.positionPercent[this.shopPlayerActiveItem.id] || [];
                    if (pPercents[0] && pPercents[pPercents.length - 1] !== 1) {
                      this.sendProgressEvent(this.shopPlayerActiveItem, this.playbackState, 100);
                      pPercents[pPercents.length - 1] = 1;
                      this.positionPercent[this.shopPlayerActiveItem.id] = pPercents;
                    }
                  }

                  this.setShopPlayerActiveItem(this.tabsItems[liveTab.id][index] || this.tabsItems[liveTab.id][index - 1]);
                }
              }
            }, 8000); // todo: timeout
          } else if ((data.op === 'started' || data.op === 'on_air_now') && !this.shopPlayerItemsLoading) {
            const latestItems = await this.loadShopPlayerItems('live', liveTab);
            if (!this.tabsItems[liveTab.id].length) {
              this.$set(this.tabsItems, liveTab.id, latestItems);
            } else {
              const newItem = latestItems.find((item) => {
                return item.id === data.room;
              });
              const existItem = this.tabsItems[liveTab.id].find((item) => {
                return item.id === data.room;
              });
              if (newItem && !existItem) {
                this.$set(this.tabsItems, liveTab.id, [...this.tabsItems[liveTab.id], newItem]);
              }
            }
          }
        }, 1000));
      },
      async loadShopPlayerItems(type, tab) {
        let items = [];
        try {
          const query = { page: 1, pageSize: 20 };
          const store = amsClient.get('conf.store');
          if (store?.provider_id) {
            query.provider_id = store?.provider_id;
          } else {
            return [];
          }
          if (type === 'live') {
            items = await RecommendationsApi.getLIVERecommendations(query);//amsClient.callAms('/on_air/', { cache: false });
          } else {
            items = await RecommendationsApi.getVODRecommendations(query);//await amsClient.callAms(this.shopPlayerVodSection.link);
          }
        } catch (e) {
          console.warn(e);
        }
        await this.onPlayTemplateReady();
        return items.map((item) => {
          const playbackUrl = ShopPlayerService.generatePlaybackUrl(item, this.getPlayTemplate);
          if (playbackUrl) {
            item.playback_url = playbackUrl;
          }
          item.uniq_id = this.generateUniqIdForPlayer(item);
          item.tab_id = tab.id;
          return item;
        });
      },

      async selectTab({ tab, lite }) {
        if (lite) {
          this.activeTab = { ...tab };
        } else {
          await this.shopPlayerAreaClick();
          this.shopPlayerItemsLoading = true;
          this.activeTab = { ...tab };
          let nextActiveItem = this.tabsItems[tab.id][0];

          await this.setShopPlayerActiveItem(nextActiveItem);

          setTimeout(() => {
            this.shopPlayerItemsLoading = false;
          }, 100);
        }
      },
      async shopPlayClick($event) {
        this.shopPlayerAreaClick({ ...$event, play: true });
      },
      async shopPlayerAreaClick($event) {
        if (this.floatItem && $event?.play) {
          this.hideFloatPlayer();
        }
      },
      shopPlayerPlaybackStateUpdated({ itemId, state }) {
        if (itemId === this.shopPlayerActiveItem?.id) {
          this.shopPlayerPlaybackState = state;
          if (
            state.duration
            && !isNaN(state.duration)
            && this.itemsProducts[itemId]
            && this.rewindToProduct[itemId]
          ) {
            const toProduct = this.itemsProducts[itemId].find((banner) => banner.slug === this.rewindToProduct[itemId]);
            delete this.rewindToProduct[itemId];
            if (toProduct) {
              this.$refs.shopPlayer.seekTo(
                null,
                toProduct.time_in / 1000,
              );
              this.$refs.shopPlayer.handleProductClick(toProduct);
            }
          }
        }
      },
      async setShopPlayerActiveItem(item) {
        if (!item.chat_id) {
          if (this.loadingItemId === item.uniq_id) {
            return;
          }

          this.loadingItemId = item.uniq_id;

          const fullItem = item.chat_id ? item : await this.loadFullItem(item);

          if (this.loadingItemId === fullItem.uniq_id) {
            this.shopPlayerActiveItem = fullItem;
          }
        } else {
          this.shopPlayerActiveItem = item;
        }
        console.log('[ShopPortal][setShopPlayerActiveItem]', item.id, { ...this.shopPlayerActiveItem });

        const slug = this.shopPlayerActiveItem.slug;
        if (slug && this.$route.params.slug !== slug) {
          this.$router.replace({
            name: 'WatchItem',
            params: {
              slug: slug,
            },
          });
        }

        return true;
      },
      fullScreenChanged({ active, emulated }) {
        // console.log('[fullScreenChanged]', { active, emulated });
        if (active && this.floatItem) {
          this.hideFloatPlayer();
        }
        this.shopPlayerFullScreenChanged({ active, emulated });
      },
    },
  };
</script>
<style lang="scss">
  .watch-shop-player {
    background-color: black;
    height: 100%;

    .app-container {
      height: 100%;
      background-color: black;

      .with-shop-player {
        min-height: 100%;
        margin-top: 0 !important;
        padding-top: 0 !important;

        &--mobile {
          min-height: 100%
        }
      }
    }

    &.with-mobile-menu {
      .app-container {
        .with-shop-player {
          &--mobile {
            min-height: calc(100% - 56px);
          }
        }
      }
    }
  }

</style>